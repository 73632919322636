import React from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid, MenuItem, Divider } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import Cookies from 'js-cookie';
//import Printer from 'esc-pos-printer';
import { getCurrentDateTime } from '../../../config/functions';
import { render, Printer, Text, Cut, Cashdraw, Br, Row, Line } from 'react-thermal-printer';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';

// COMPONENTES
import MyMoneyInput from '../../../components/inputs/MyMoneyInput';
import MySelect from '../../../components/inputs/MySelect';

const ModalCobrarOrden = ({ open, onClose, articulos, total, idOrden, refreshOrdenes, tipoPedido }) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = async (values) => {

        // VALIDAMOS QUE SE HAYA GUARDADO ALGO
        // if(values.pago_efectivo === '' && values.pago_tarjeta === ''){

        //     return;

        // }

        // ARMAMOS EL JSON DE DATOS
        const sendData = {

            // SECION PARA PROPINA
            rel_user: Cookies.get('idUser'),
            monto: values.propina_monto !== '' ? values.propina_monto : '$0.00',
            comentario: 'Reembolso Propina',

            // SECCION PARA CIERRE DE ORDEN
            tipo_pedido: tipoPedido,
            cobro_envio: tipoPedido === 'Envio' ? Cookies.get('costo_envio') : '$0.00',
            cuenta: total.cuenta,
            iva: total.iva,
            total: total.total,
            tipo_tarjeta: values.tipo_tarjeta,
            pago_efectivo: values.pago_efectivo !== '' ? values.pago_efectivo : '$0.00',
            pago_tarjeta: values.pago_tarjeta !== '' ? values.pago_tarjeta : '$0.00',
            propina_medio: values.propina_medio,
            propina_monto: values.propina_monto !== '' ? values.propina_monto : '$0.00',
            cambio: values.cambio,
            fecha_cerrada: getCurrentDateTime(),
            id: idOrden

        }

        const url = 'ordenes/cerrar';

        // MANDAMOS EL POST

        api.put(url, sendData)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                refreshOrdenes();

                guardarNuevosArticulos(articulos, idOrden);

                // Swal.fire({
                //     icon: 'success',
                //     title: 'Orden Cerrada!',
                // })

                printTicket(values, 'cobro');

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================
    // FUNCION PARA IMPRIMIR TICKET
    // ===============================================

    const printTicket = async (values, option) => {

        try {

            const ticket = await render(

                <Printer type="epson">

                    <Text align="center" bold={true} size={{ width: 3, height: 3 }} >CILANTRO</Text>
                    <Br />
                    <Line character="=" />
                    <Text align="center" >Prolongacion Guerrero #4635, Col. Campestre</Text>
                    <Text align="center" >Nuevo Laredo, Tam.</Text>
                    <Row left="Telefono: 867 137 8575" right={`Fecha: ${new Date().toLocaleDateString()}`} />
                    <Line character="=" />
                    <Text align="center" bold={true} >Articulos</Text>
                    <Line character="=" /> 

                    {/* ARTICULOS DE LA ORDEN */}
                    
                    {
                        articulos.map((articulo, index) => {

                            const precioArticulo = parseFloat(articulo.precio.replace(/[^\d.-]/g, ''));
                            const precioTotal = precioArticulo * articulo.cantidad;
                            
                            return (

                                <Row key={index} left={`Cant ${articulo.cantidad} - ${articulo.descripcion}`} right={`$${precioTotal.toFixed(2)}`}/>

                            )

                        })
                    }

                    <Line character="=" />
                    <Text align="right">Subtotal: {total.cuenta}</Text>

                    {/* VALIDAMOS SI LLEVA EL CARGO POR ENVIO */}

                    {
                        tipoPedido === 'Envio' && (

                            <Text align="right">Envio: {Cookies.get('costo_envio')}</Text>

                        )
                    }

                    <Text align="right">Iva({Cookies.get('iva')}%): {total.iva}</Text>
                    <Text align="right">Total: {total.total}</Text>
                    <Text align="right">Pago Efectivo: {values.pago_efectivo}</Text>
                    <Text align="right">Pago Tarjeta: {values.pago_tarjeta}</Text>
                    <Br />
                    <Text align="right">Cambio: {values.cambio}</Text>

                    <Cut />

                    {/* INCLUIR CASHDRAW SOLO SI EL PARAMETRO ES 'cobro' */}
                    {option === "cobro" && <Cashdraw pin="2pin" />}

                </Printer>

            );
        
            // // USAMOS EL PUERTO DISPONIBLE
            // await navigator.serial.getPorts().then(async (ports) => {console.log(ports)
        
            //     // SELECCIONAMOS EL PUERTO DISPONIBLE
            //     const port = ports[0];

            //     // VALIDAMOS LA CONEXION DEL PUERTO

            //     try {

            //         await port.open({ baudRate: 9600 });

            //     } catch( e ) {

            //         console.log(e)

            //     }
        
            //     // HABILITAMOS EL PUERTO PARA ESCRIBIR
            //     const writer = await port.writable?.getWriter(); console.log(writer)
            
            //     if (writer != null) {

            //         // IMPRIMIMOS EL TICKET
            //         await writer.write(ticket);

            //         writer.releaseLock();
                    
            //     } else {

            //         console.log('ERROR AL OBTENER EL WRITER');

            //     }

            // });

            const port = await window.navigator.serial.requestPort();

            try {

                await port.open({ baudRate: 9600 });

            } catch( e ) {

                console.log(e)

            }

            const writer = port.writable?.getWriter();

            if (writer != null) {

                await writer.write(ticket);
                await writer.write(ticket);
                
                writer.releaseLock();

                Swal.fire({
                    icon: 'success',
                    title: 'Orden Cerrada!',
                })

            }
        
        } catch (e) {
        
            console.log(e);

            // printTicket();
        
        }

    }

    // ===============================================
    //  GUARDAR NUEVOS ARTICULOS
    // ===============================================

    const guardarNuevosArticulos = (articulos, idOrden) => {

        // VALIDAMOS SI SE AGRREGO UN ARTICULO NUEVO

        const nuevosArticulos = articulos.filter(obj => obj.nuevo === 'si');

        if(nuevosArticulos.length > 0){

            // FORMATEAMOS EL JSON PARA SER RECIBIDO POR EL ENDPOINT

            const data = {

                datosOrden: { id: idOrden },
                articulosOrden: articulos

            }

            // URL PARA EL POST

            const url = 'ordenes/guardar/articulos';

            api.post(url, data);

        }


    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose()}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    cambio: '$0.00',
                    tipo_tarjeta: 'N/A',
                    pago_efectivo: '',
                    pago_tarjeta: '',
                    propina_medio: 'N/A',
                    propina_monto: ''
                }}
                validationSchema={Yup.object({
                    propina_medio: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting, setFieldValue, values }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `30%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography 
                            variant="h3" 
                            align="center" 
                            marginBottom={1}
                        >
                            Cobrar
                        </Typography>
                        <Typography 
                            variant="h1" 
                            align="center"
                            fontWeight={'bold'}
                        >
                            {total.total}
                        </Typography>

                        {/* VALIDAMOS SI SE INCLUYE COSTO DE ENVIO */}

                        {
                            tipoPedido === 'Envio' && (

                                <Typography 
                                    variant="h5" 
                                    align="center"
                                    color={'grey'}
                                    marginTop={1}
                                >
                                    {`+ ${Cookies.get('costo_envio')} de Envio`}
                                </Typography>

                            )
                        }

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >

                                {/* TIPO TARJETA */}

                                <Grid item xs={6}>

                                    <MySelect
                                        name="tipo_tarjeta"
                                        label="Tipo Tarjeta"
                                    >
                                        <MenuItem value={'N/A'}>No Aplica</MenuItem>
                                        <MenuItem value={'Debito'}>Debito</MenuItem>
                                        <MenuItem value={'Credito'}>Credito</MenuItem>
                                    </MySelect>

                                </Grid>

                                {/* MONTO TARJETA */}

                                <Grid item xs={6}>

                                    <MyMoneyInput
                                        name="pago_tarjeta"
                                        label="Pago Tarjeta"
                                        onChange={(event) => {

                                            const thisValue = event.target.value;

                                            const monto_tarjeta = thisValue !== '' ? parseFloat(thisValue.replace(/[^\d.-]/g, '')) : 0;
                                            const monto_efectivo = values.pago_efectivo !== '' ? parseFloat(values.pago_efectivo.replace(/[^\d.-]/g, '')) : 0;

                                            const montoTotal = monto_tarjeta + monto_efectivo;

                                            const totalCuenta = parseFloat(total.total.replace(/[^\d.-]/g, ''));

                                            const cambio = (totalCuenta - montoTotal);
                                            const formatCambio = cambio * -1

                                            setFieldValue('cambio', `$${formatCambio.toFixed(2)}`);
                                            setFieldValue('pago_tarjeta', thisValue);

                                        }}
                                    />

                                </Grid>

                                {/* MONTO EFECTIVO */}

                                <Grid item xs={6}>

                                    <MyMoneyInput
                                        name="pago_efectivo"
                                        label="Pago Efectivo"
                                        onChange={(event) => {

                                            const thisValue = event.target.value;

                                            const monto_efectivo = thisValue !== '' ? parseFloat(thisValue.replace(/[^\d.-]/g, '')) : 0;
                                            const monto_tarjeta = values.pago_tarjeta !== '' ? parseFloat(values.pago_tarjeta.replace(/[^\d.-]/g, '')) : 0;

                                            const montoTotal = monto_tarjeta + monto_efectivo;

                                            const totalCuenta = parseFloat(total.total.replace(/[^\d.-]/g, ''));

                                            const cambio = (totalCuenta - montoTotal);
                                            const formatCambio = cambio * -1

                                            setFieldValue('cambio', `$${formatCambio.toFixed(2)}`);
                                            setFieldValue('pago_efectivo', thisValue);

                                        }}
                                    />

                                </Grid>

                                {/* CAMBIO */}

                                <Grid item xs={6}>

                                    <Typography
                                        textAlign={'center'}
                                        variant='h2'
                                        sx={{
                                            //textDecoration: 'underline grey',
                                            marginTop: 1
                                        }}
                                    >
                                        <span style={{ fontSize: 20 }}>Cambio:</span> {values.cambio}
                                    </Typography>

                                </Grid>

                                {/* DIVIDER */}

                                <Grid item xs={12}>

                                    <Divider></Divider>

                                </Grid>

                                {/* PROPINA */}

                                <Grid item xs={12}>

                                    <Typography 
                                        variant="h3" 
                                        align="center" 
                                        marginBottom={1}
                                    >
                                        Propina
                                    </Typography>

                                </Grid>

                                {/* TIPO PAGO */}

                                <Grid item xs={6}>

                                    <MySelect
                                        name="propina_medio"
                                        label="Medio Propina"
                                    >
                                        <MenuItem value={'N/A'}>Sin Propina</MenuItem>
                                        <MenuItem value={'Efectivo'}>Efectivo</MenuItem>
                                        <MenuItem value={'Debito'}>Debito</MenuItem>
                                        <MenuItem value={'Credito'}>Credito</MenuItem>
                                    </MySelect>

                                </Grid>

                                {/* MONTO EFECTIVO */}

                                <Grid item xs={6}>

                                    <MyMoneyInput
                                        name="propina_monto"
                                        label="Monto"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={() => onClose()}
                            >
                                Cancelar
                            </Button>

                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => printTicket(values, 'print')}
                                sx={{color: "white", backgroundColor: 'grey'}}
                            >
                                <PrintIcon/>
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalCobrarOrden